import React, { useState, useEffect } from "react";
import { ExternalScriptWidget } from "shared-components";
import Header from "../../Components/Header/Header";
import { useTranslation } from "react-i18next";

const RaizSalud = () => {
  const [widgetData, setWidgetData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Simulamos la recepción de datos (por ejemplo, desde un backend)
    const data = {
      scriptUrl:
        "https://widget.proyectosyseguros.com:9080/widget/widgetpys/vuskoowidget/?api-key=PYSW-iXIqT9BfSmkAYhtcPWEnMywu&filtro=SALUD",
      containerId: "pys-widget",
      containerClassName: "custom-widget-class",
      targetSelector: "body",
    };
    setWidgetData(data);
  }, []); // El array vacío asegura que esto se ejecute solo una vez

  if (!widgetData) {
    return <div>Cargando widget...</div>;
  }
  return (
    <>
      <Header breadCrumb></Header>
   
      <ExternalScriptWidget {...widgetData} />
    </>
  );
};

export default RaizSalud;
